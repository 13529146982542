import { createSlice, current } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash';


const countNonEmptyFilters = (filters, initialVal) => {
    return Object.entries(filters).reduce((acc, [key, val]) => {
        if ((Array.isArray(val) && val.length === 0) || val === undefined || val === null || val === '') {
            return acc;
        }
        acc += 1;
        return acc;
    }, initialVal);
}


// initial state
const initialState = {
    count: 0,
    filters: {
        list: {
            planIdList: [],
        },
        sort: {},
        limit: 20,
        offset: 0,
        page: 1
    }
};


const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setFilters(state, action) {
            const { list = {}, ...restFilters } = action.payload;
            state.filters = { ...state.filters, list: { ...state.filters.list, ...list }, ...restFilters };
            state.count = countNonEmptyFilters(state.filters.list, initialState.count);
        },

        clearFilters(state, action) {
            state.filters = initialState.filters;
            state.count = initialState.count;
        }
    }
});

export default notifications.reducer;

export const { setFilters, clearFilters } = notifications.actions;
