// third-party
import { combineReducers } from 'redux';

import cart from './cart';
import account from './account';
import profile from './profile';
import onechips from './onechips';
import notifications from './notifications';
import feed from './feed';
import orderHistory from './order-history';
import paymentHistory from './paymentHistory';
import manualInstallOnechips from './manual-install-onechips';
// ==============================|| COMBINE REDUCERS ||============================== //

const appReducer = combineReducers({ cart, account, profile, onechips, notifications, feed, orderHistory, paymentHistory, manualInstallOnechips });

const rootReducer = (state, action) => {
    return appReducer(state, action)
}


export default rootReducer;
