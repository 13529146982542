import Maintenance from 'components/Error/Maintenance';
import { useSearchParams } from 'react-router-dom';
import SignInWithToken from 'views/authentication/SignInWithToken';

const LOGIN_TOKEN_PARAM = 'x_t_id';
export function RequireTokenSignIn({ children }) {
    const [searchParams] = useSearchParams();

    if (searchParams.has(LOGIN_TOKEN_PARAM)) {
        const loginToken = searchParams.get(LOGIN_TOKEN_PARAM);
        return <SignInWithToken loginToken={loginToken} />;
    }

    return children;
}
