import { useMemo } from 'react';
import { styled, Stack, Box } from '@mui/material';
import { SimpleListItem } from 'components/shared/SimpleList';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)(({ theme }) => ({
    color: '#151F57',
    fontSize: '16px'
}));


const Footer = () => {

    const { t } = useTranslation();

    const menu = useMemo(() => [
        {
            title: t("footer.tcs"),
            url: CONFIG.app.links.footer.termsAndConditionsUrl
        },
        {
            title: t("footer.privacy_policy"),
            url: CONFIG.app.links.footer.privacyPolicyUrl
        },
    ], []);

    return (<Stack spacing={2} alignItems={'center'} direction="column">
        <img src={CONFIG.app.links.footer_logo_url} alt="Logo" />
        <Box>
            {menu.map((menuItem) => (
                <SimpleListItem key={`footer-${menuItem.title}`}>
                    <StyledLink href={menuItem.url} target="_blank">{menuItem.title}</StyledLink>
                </SimpleListItem>
            ))}
            <SimpleListItem>{t("footer.version", { version: VERSION })}</SimpleListItem>
        </Box>
    </Stack>)
}

export default Footer;