import { Navigate } from 'react-router-dom';
import TokenService from 'services/token/Token';
import LoginView from 'views/authentication/Login';

export function RequireLoginView({ children }) {
    const authToken = TokenService.getData();
    const canAccess = Boolean(authToken);

    if (!canAccess) {
        return <LoginView />;
    }

    return children;
}
