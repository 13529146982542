import { createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { countNonEmptyFilters } from 'utils/functions';

// initial state
const initialState = {
    count: 0,
    filters: {
        list: {
            planIdList: [],
        },
        limit: 20,
        offset: 0,
        page: 1
    }
};


const onechips = createSlice({
    name: 'onechips',
    initialState,
    reducers: {
        setFilters(state, action) {
            const { list = {}, ...restFilters } = action.payload;
            state.filters = { ...state.filters, list: { ...state.filters.list, ...list }, ...restFilters };
            state.count = countNonEmptyFilters(state.filters.list, initialState.count);
        },

        clearFilters(state, action) {
            state.filters = cloneDeep(initialState.filters);
            state.count = initialState.count;
        }
    }
});

export default onechips.reducer;

export const { setFilters, clearFilters } = onechips.actions;
