import { getI18n } from "react-i18next";

export const pick = function (data, key) {
    const list = Array.isArray(data) ? data : [];
    return list.map((val) => {
        return typeof key === 'function' ? key(val) : val[key];
    }, []);
};

export const divideIfNotZero = (numerator, denominator) => {
    if (denominator === 0 || isNaN(denominator)) {
        return 0;
    }
    else {
        return numerator / denominator;
    }
}

export const calculateDeviation = (currentUsage, previousUsage) => {
    if (isNaN(currentUsage)) {
        currentUsage = 0;
    }
    if (isNaN(previousUsage)) {
        previousUsage = 0;
    }
    const value = ((currentUsage - previousUsage) / previousUsage) * 100;
    return {
        trend: value === 0 ? 'equal' : value > 0 ? 'up' : 'down',
        value: Math.abs(value).toFixed(2)
    }
}

export const roundCurrencyCentsToWholeNumber = (value) => {
    if (isNaN(value)) return value;
    return value / 100;
}


export const setNestedKey = (obj, path, value) => {
    if (!obj[path[0]]) {
        obj[path[0]] = {};
    }
    if (path.length === 1) {
        obj[path] = value;
        return;
    }
    return setNestedKey(obj[path[0]], path.slice(1), value);
};

const STORE_UNIT = Object.freeze({
    BYTES: 'BYTES',
    KB: 'KB',
    MB: 'MB',
    GB: 'GB',
    TB: 'TB'
});
const STORE_UNIT_LIST = Object.keys(STORE_UNIT);

export const getStoreUnitFromBytes = (bytes, unit = 'detect', decimals = 2) => {
    let i = unit in STORE_UNIT ? STORE_UNIT_LIST.indexOf(unit) : unit;

    if (bytes === 0) return { value: 0, unit: STORE_UNIT_LIST[i] ?? STORE_UNIT.KB };

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;

    if (unit === 'detect') {
        i = Math.floor(Math.log(bytes) / Math.log(k));
    }

    const formattedNumber = getI18n().format(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), "number", undefined);

    return {
        value: formattedNumber,
        unit: STORE_UNIT_LIST[i]
    };
};

export const getBytesFromStoreUnit = (value, unit = 'MB', decimals = 2) => {
    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    if (!(unit in STORE_UNIT)) {
        throw new Error('Cannot convert to bytes: No such unit type');
    }
    const i = STORE_UNIT_LIST.indexOf(unit);

    return parseFloat(value * Math.pow(k, i).toFixed(dm));
};

export const countNonEmptyFilters = (filters, initialVal) => {
    return Object.entries(filters).reduce((acc, [key, val]) => {
        if ((Array.isArray(val) && val.length === 0) || val === undefined || val === null || val === '') {
            return acc;
        }
        acc += 1;
        return acc;
    }, initialVal);
}

