import PropTypes from "prop-types";
import { Autocomplete, Box, CircularProgress, InputAdornment, Skeleton, Stack, styled, TextField } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useMemo, useState } from "react";
import { AccountSelect } from "components/account-select/AccountSelect";
import { useDispatch } from "react-redux";
import { setSelected } from "store/reducers/account";
import { debounce } from "@mui/material/utils";
import ClientsService from "services/clients/Clients";
import OnechipsService from "services/onechips/Onechips";
import { useAsyncNew } from "hooks/useAsyncNew";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { ROUTES } from "routes/const";
import Breadcrumbs from "components/breadcrumb";
import useStateAsync from "hooks/useStateAsync";
import { setFilters } from "store/reducers/onechips";
import loglevel from 'loglevel';
import withLoader from "hoc/witLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { trim } from "lodash";

const prepareData = (data) => {
    return data.map((item) => {
        return {
            group: 'eIDs',
            ...item
        };
    });
};

const mainCardStyles = {
    height: '64px',
    position: 'relative',
    minWidth: '268px',
    backgroundColor: '#fff',
    mb: 2
};

const mainCardContentStyles = {
    display: 'flex',
    height: '100%',
    padding: '0 20px'
};

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 16px',
    color: '#7A7B84',
    backgroundColor: '#ffffff'
}));

const GroupItems = styled('ul')({
    padding: 0
});


const Loader = (props) => {
    const { children, ...rest } = props;
    return withLoader(() => <>{children}</>);
}

const ServerAutoComplete = ({ data, loading, onSearch, onSelect }) => {
    const newData = useMemo(() => {
        return prepareData(data?.data ?? []);
    }, [data]);

    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (inputValue.length === 0) {
            setOptions([]);
            return;
        }
        onSearch(inputValue);
    }, [inputValue]);

    useEffect(() => {
        if (newData) setOptions(newData);
    }, [newData]);

    const handleonChange = (event, value, reason) => {
        onSelect(value, reason);
    };

    const handleOnInputChange = debounce((event, value, reason) => {
        if (reason !== 'input') return;
        setInputValue(value);
    }, 600);

    return (
        <Autocomplete
            sx={{
                width: { xs: '100%', md: 350 },
                height: '40px',
                '& .MuiOutlinedInput-root': { paddingLeft: '10px', paddingRight: '30px !important' }
            }}
            options={options}
            size="small"
            freeSolo
            disableClearable={false}
            value={inputValue}
            autoComplete
            filterSelectedOptions
            filterOptions={(x) => x}
            groupBy={(option) => option.group}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
            getOptionLabel={(option) => typeof option === "string" ? option : option.eid}
            onInputChange={(...args) => handleOnInputChange(...args)}
            onChange={handleonChange}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    sx={{ borderRadius: '8px', height: 'inherit' }}
                    name="search"
                    size="small"
                    {...params}
                    InputProps={{
                        ...params?.InputProps,
                        startAdornment: (
                            <InputAdornment position="start" sx={{ pl: 1 }}>
                                <img src="/search_icon.svg" alt="" />
                            </InputAdornment>
                        ),
                        endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>
                    }}
                    placeholder={t('common.top_bar.search_placeholder')}
                />
            )}
        />
    );
};


const TopSection = ({ children }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const onechips = useStateAsync(OnechipsService.getOnechipsList, { immediate: false });
    const clients = useStateAsync(ClientsService.getClientsList, { clientQueryParameterName: "clientIds", immediate: false });

    useEffect(() => {
        clients.execute({ 'sort.name': "ASC" });
    }, []);

    const dispatch = useDispatch();

    const handleAccountSelectSearch = (value) => {
        // clientIds is set to null to unset from request query
        clients.execute({ title: value, clientIds: null });
    };

    const handleAccountSelect = (value, type) => {
        dispatch(setSelected({ selected: { ...value, multiple: type === 'all' } }));
    };

    const handleonSearch = (value) => {
        const values = value.split(/[,;\s]/g);
        if (values.length > 1) {
            return;
        }
        onechips.execute({ eid: values });
    };

    const handleOnSearchOptionSelect = (option, reason) => {
        if (reason !== "selectOption") return;
        const { eid = null } = option;
        navigate(generatePath(ROUTES.ONECHIP_DETAILS.INDEX, { eid }));
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const search = formData.get('search');
        dispatch(setFilters({ list: { eid: trim(search) } }));
        navigate(ROUTES.ONECHIPS);
    };

    const accountsData = clients?.data?.data?.data || [];

    const [defaultSelectedAccount, setDefaultSelectedAccount] = useState(null)

    useEffect(() => {
        if (!accountsData) return;
        if (clients.isInitialRequest && accountsData.length === 1) {
            const defaultAccount = accountsData[0];
            setDefaultSelectedAccount(defaultAccount)
            dispatch(setSelected({ selected: defaultAccount }));
            return;
        }
    }, [clients.isInitialRequest, accountsData])

    const showContent = useMemo(() => {
        if (clients.isSuccess || !clients.isInitialRequest) {
            return true;
        }
        return false;
    }, [clients])

    return (
        <>
            <MainCard contentSX={mainCardContentStyles} sx={mainCardStyles}>
                <Stack direction="row" alignItems="center" style={{ width: '100%' }} justifyContent="space-between">
                    <Breadcrumbs />
                    <Stack direction="row" justifySelf={'end'} spacing={6} alignItems="center" justifyContent="end">
                        {loading ? (
                            <Skeleton variant="rectangular" width={'350px'}>
                                <Box alignItems="left" width={'100%'} height={'40px'} direction="column">
                                </Box>
                            </Skeleton>
                        ) : (<>
                            <Box sx={{
                                ml: 2,
                                color: 'text.secondary'
                            }}>All times in UTC</Box>
                            <form onSubmit={onSubmit}>
                                <ServerAutoComplete
                                    loading={onechips.isLoading}
                                    data={onechips?.data?.data}
                                    onSearch={handleonSearch}
                                    onSelect={handleOnSearchOptionSelect}
                                />
                            </form>
                        </>
                        )}
                        <AccountSelect
                            isInitiallyLoaded={clients.isInitialRequest}
                            loading={clients.isLoading}
                            data={accountsData}
                            defaultSelectedAccount={defaultSelectedAccount}
                            optionConfig={{ id: 'id', text: 'title', image: 'image', uuid: 'uuid' }}
                            onSearch={handleAccountSelectSearch}
                            onSelect={handleAccountSelect}
                        />
                    </Stack>
                </Stack>
            </MainCard>
            {showContent ?
                <>
                    {children}
                </>
                : <LoadingOutlined style={{ fontSize: '36px', color: '#08c' }} />
            }
        </>
    );
};

TopSection.propTypes = {
    onAccountSelect: PropTypes.func
};

TopSection.defaultProps = {
    onAccountSelect: () => { }
};

export default TopSection;
